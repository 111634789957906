import { Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import successIcon from '../../../images/password-reset.svg';
import GenericSuccessModal from '../../common/modals/success/GenericSuccess';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import FormWrapper from '../../common/wrappers/FormWrapper';

function CompanyInfoDetails({ AppStore, CompanyInfoDetailsStore, onCancel, onSave }) {
    const { t } = AppController.getTranslation(AppStore.scope, ['settings-company-info-details']);
    const {
        loading,
        disabled,
        isFormFilled,
        renderCompanyForm,
        handleFetch,
        isTppCompanyDetails,
    } = CompanyInfoDetailsStore;
    const ref = React.createRef();

    useEffect(() => {
        if (!isTppCompanyDetails) {
            handleFetch();
        }
    }, []);

    return (
        <div className={'CompanyInfoDetails'}>
            <FormWrapper
                className="form sectionParameters"
                formRef={ref}
                onSubmit={onSave}
                isValid={isFormFilled}>
                {renderCompanyForm(t)}
            </FormWrapper>
            <Grid className={'divider'} item xs={12}>
                <Divider />
            </Grid>
            <Grid className={'buttonContainer'} container spacing={4}>
                {onCancel && (
                    <Grid item xs={3}>
                        <ButtonWrapper
                            disabled={loading}
                            className="cancelButton"
                            color="secondary"
                            onClick={onCancel}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                )}
                <Grid item xs={3}>
                    <ButtonWrapper
                        loading={loading}
                        className="submitButton"
                        onClick={onSave}
                        disabled={!isFormFilled || disabled}>
                        {t('common:save')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <GenericSuccessModal
                Store={CompanyInfoDetailsStore.GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('modalButton')}
                icon={successIcon}
            />
        </div>
    );
}

CompanyInfoDetails.propTypes = {
    CompanyInfoDetailsStore: PropTypes.object.isRequired,
    AppStore: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
};

CompanyInfoDetails.defaultProps = {
    onSave: async () => Promise.resolve(),
};

export default inject('AppStore')(observer(CompanyInfoDetails));
