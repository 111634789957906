import {
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    RadioGroup,
    Radio,
    Tooltip,
    TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import successIcon from '../../../images/password-reset.svg';
import DefaultContainer from '../../../layout/DefaultContainer';
import CompanyInfoDetails from '../../account-settings/company-info/CompanyInfoDetails';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import GenericSuccessModal from '../../common/modals/success/GenericSuccess';
import { AIS, PIS } from 'constants/permissions';
import { ADMIN } from 'constants/banks';
import { TYPE_TWO, TYPE_ONE } from 'constants/memberTypes';

import snackbar from 'hooks/useSnackbar';
import { withStyles } from '@material-ui/core/styles';

const { useSnackbar } = snackbar;
const StyledIcon = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'inset 0 0 10px white',
        },
    },
})(IconButton);

// toggle type without render
let currentSnackbarType = 'info';

function CreateTppStep1({ AppStore, TppManagerStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'create-tpp');
    const { sendToTppManager, CompanyInfoDetailsStore, nextStep, member } = TppManagerStore;
    const {
        appType,
        clearForm,
        handleSubmit,
        handleCompanyAliasChange,
        handleChecked,
        handleMemberTypeChange,
        handleSinglePermissions,
        hidePermissionsForm,
        memberType,
        resellerType,
        resellerTypes,
        handleResellerTypeChange,
        disableStep,
        openSnackbar,
        snackbarKeys,
        updateSnackbarKeys,
        resellerError,
        companyInfo,
        fetchResellersWithRealmId,
    } = CompanyInfoDetailsStore;

    const type1Copy =
        AppStore.scope === ADMIN
            ? "Use Reseller's license"
            : companyInfo.companyName
                ? `Use ${companyInfo.companyName}'s license`
                : "Use your company's license";

    const _getCurrentMemberType = () => {
        if (member) {
            return member.memberType;
        }
        return AppStore.member.memberType;
    };

    const _getDefaultMemberType = () => {
        return _getCurrentMemberType() === TYPE_TWO ? TYPE_ONE : TYPE_TWO;
    };

    const _getUserInfo = () => {
        return member || AppStore.user;
    };

    useEffect(() => {
        if (!disableStep) {
            handleSinglePermissions(_getUserInfo());
            handleMemberTypeChange({ target: { value: _getDefaultMemberType() } });
        }
    }, [disableStep]);

    const { addSnack, closeSnack } = useSnackbar();
    const action = (key) => {
        updateSnackbarKeys(currentSnackbarType, key);
        return (
            <>
                <StyledIcon
                    size="small"
                    className="close-snackbar"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        closeSnack(key);
                    }}>
                    <CloseIcon fontSize="small" />
                </StyledIcon>
            </>
        );
    };

    useEffect(() => {
        if (openSnackbar) {
            if (resellerError) {
                // close the previously open info snackbar
                closeSnack(snackbarKeys['info']);
                currentSnackbarType = 'error';
                addSnack(t('resellerError'), {
                    variant: 'error',
                    autoHideDuration: null,
                    action,
                });
            } else {
                // close the previously open error snackbar if found
                closeSnack(snackbarKeys['error']);
                currentSnackbarType = 'info';
                addSnack(t('resellerLoading'), {
                    variant: 'info',
                    autoHideDuration: null,
                    action,
                });
            }
        } else {
            // hide snackbar programatically when reseller info is fetched correctly.
            closeSnack(snackbarKeys['info']);
        }
    }, [openSnackbar, resellerError]);

    useEffect(() => {
        if (AppStore.scope !== ADMIN) return;
        const cancellablePromise = fetchResellersWithRealmId();

        return () => {
            cancellablePromise && cancellablePromise.cancel();
        };
    }, []);

    const _handleCancel = () => {
        sendToTppManager();
        handleCompanyAliasChange({ target: { value: '' } });
        return clearForm();
    };

    const _handleSubmit = async () => {
        await handleSubmit(AppStore);
    };

    return (
        <div className={'createTpp'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={_handleCancel}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Divider />
                {AppStore.scope === ADMIN && (
                    <Grid item xs={3} className={'resellerType'}>
                        <Autocomplete
                            id="reseller"
                            options={resellerTypes}
                            value={resellerType}
                            getOptionLabel={(option) => option.name || ''}
                            onChange={handleResellerTypeChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('resellerType')}
                                    name={'reseller'}
                                />
                            )}
                            placeholder={t('enterReseller')}
                        />
                    </Grid>
                )}
                <div style={disableStep ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
                    {_getCurrentMemberType() === TYPE_TWO && (
                        <Grid container spacing={2} className={'member-info'}>
                            <Grid item xs={12}>
                                <h2>{t('Member Information')}</h2>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={'productInfo'}>{t('Member Types')}</div>
                            </Grid>
                            <Grid item xs={10}>
                                <RadioGroup
                                    aria-label="member types"
                                    name="memberType"
                                    value={memberType || _getDefaultMemberType()}
                                    onChange={handleMemberTypeChange}
                                    className={'radioGroup'}>
                                    <FormControlLabel
                                        value={TYPE_ONE}
                                        control={<Radio />}
                                        label={type1Copy}
                                        className="unit-test-type-1-copy"
                                    />
                                    <FormControlLabel
                                        value={TYPE_TWO}
                                        control={<Radio />}
                                        label="Use TPP’s own license"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    )}
                    {!disableStep && !hidePermissionsForm && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2>{t('productInfo')}</h2>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={'productInfo'}>{t('products')}</div>
                            </Grid>
                            {_getUserInfo()
                                .permissions.filter(
                                    (permission) => permission === AIS || permission === PIS,
                                )
                                .map((key, index) => (
                                    <Grid
                                        item
                                        xs={2}
                                        md={[2, 2][index]}
                                        key={key}
                                        className={'checkboxGroup'}>
                                        <FormControlLabel
                                            control={
                                                <Tooltip
                                                    title={t('common:tooltips.application')}
                                                    arrow>
                                                    <Checkbox
                                                        onChange={handleChecked}
                                                        name={key}
                                                        checked={Boolean(appType[key])}
                                                    />
                                                </Tooltip>
                                            }
                                            label={t(key)}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={10}>
                            <h2>{t('companyInfo')}</h2>
                            <p className={'instructions'}>{t('instructions')}</p>
                            <CompanyInfoDetails
                                CompanyInfoDetailsStore={CompanyInfoDetailsStore}
                                onCancel={_handleCancel}
                                onSave={_handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DefaultContainer>
            <GenericSuccessModal
                Store={CompanyInfoDetailsStore.TppSuccessStore}
                onClick={() => {
                    nextStep();
                }}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('modalButton')}
                icon={successIcon}
            />
        </div>
    );
}

CreateTppStep1.propTypes = {
    AppStore: PropTypes.object.isRequired,
    TppManagerStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'TppManagerStore')(observer(CreateTppStep1));
