const { getCountries } = require('@jbreneman/country-state-list');

module.exports = {
    countries: getCountries([
        'at', // Austria
        'be', // Belgium
        'bg', // Bulgaria
        'hr', // Croatia
        'cy', // Cyprus
        'cz', // Czech Republic
        'dk', // Denmark
        'ee', // Estonia
        'fi', // Finland
        'fr', // France
        'de', // Germany
        'gr', // Greece
        'hu', // Hungary
        'ie', // Ireland
        'is', // Iceland
        'it', // Italy
        'lv', // Latvia
        'lt', // Lithuania
        'li', // Liechtenstein
        'lu', // Luxembourg
        'mt', // Malta
        'nl', // Netherlands
        'no', // Norway
        'pl', // Poland
        'pt', // Portugal
        'ro', // Romania
        'sk', // Slovakia
        'si', // Slovenia
        'es', // Spain
        'se', // Sweden
        'gb', // United Kingdom
    ]),
    supportedConfigCountries: getCountries([
        'at', // Austria
        'be', // Belgium
        'ee', // Estonia
        'de', // Germany
        'fr', // France
        'hu', // Hungary
        'lv', // Latvia
        'lt', // Lithuania
        'nl', // Netherlands
        'pl', // Poland
        'es', // Spain
        'gb', // United Kingdom
    ]),
    supportedMetricsCountries: getCountries([
        'at', // Austria
        'be', // Belgium
        'fr', // France
        'de', // Germany
        'hu', // Hungary
        'it', // Italy
        'lu', // Luxembourg
        'nl', // Netherlands
        'pl', // Poland
        'es', // Spain
        'gb', // United Kingdom
    ]),
    isoAlpha3: {
        AT: 'AUT', // Austria
        BE: 'BEL', // Belgium
        BG: 'BGR', // Bulgaria
        HR: 'HRV', // Croatia
        CY: 'CYP', // Cyprus
        CZ: 'CZE', // Czech Republic
        DK: 'DNK', // Denmark
        EE: 'EST', // Estonia
        FI: 'FIN', // Finland
        FR: 'FRA', // France
        DE: 'DEU', // Germany
        GR: 'GRC', // Greece
        HU: 'HUN', // Hungary
        IE: 'IRL', // Ireland
        IS: 'ISL', // Iceland
        IT: 'ITA', // Italy
        LV: 'LVA', // Latvia
        LT: 'LTU', // Lithuania
        LI: 'LIE', // Liechtenstein
        LU: 'LUX', // Luxembourg
        MT: 'MLT', // Malta
        NL: 'NLD', // Netherlands
        NO: 'NOR', // Norway
        PL: 'POL', // Poland
        PT: 'PRT', // Portugal
        RO: 'ROU', // Romania
        SK: 'SVK', // Slovakia
        SI: 'SVN', // Slovenia
        ES: 'ESP', // Spain
        SE: 'SWE', // Sweden
        GB: 'GBR', // United Kingdom
    },
    getAllCountry: getCountries(),
    excludedCountries:
        // Pass the countries that need to be excluded
        [
            'AL', // Albania
            'BS', // The Bahamas
            'BB', // Barbados
            'BW', // Botswana
            'KH', // Cambodia
            'GH', // Ghana
            'HT', // Haiti
            'IS', // Iceland
            'IR', // Iran
            'JM', // Jamaica
            'MU', // Mauritius
            'MN', // Mongolia
            'NI', // Nicaragua
            'KP', // North Korea
            'PK', // Pakistan
            'PA', // Panama
            'PH', // Philippines
            'SS', // South Sudan
            'SY', // Syria
            'UG', // Uganda
            'YE', // Yemen
            'ZW', // Zimbabwe
        ],
};
