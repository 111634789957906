import { Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import AppController from 'base/App.controller';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';

// helpers
import conditional from 'helpers/conditional/conditional';

// constants
import { UNDER_RESELLER } from 'constants/clientType';
import { PERFORM_KYC } from 'constants/memberPermissions';
import { IUpdatePermissionsModalProps } from '../types';
import { Itranslation } from 'types';

const UpdatePermissionsModal: React.FC<IUpdatePermissionsModalProps> = ({
    AppStore,
    UpdatePermissionsModalStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'admin-tpp-details',
        'common',
    ]);

    const {
        permissionsList,
        permissions,
        handleCheck,
        handleSave,
        isFilled,
        isAdminTppResellerFlow,
        sendToAdminPage,
        clientType,
        displayName,
        updatePermissionList,
    } = UpdatePermissionsModalStore;

    useEffect(() => {
        updatePermissionList();
    }, []);

    const handleSubmit = () => {
        return handleSave(AppStore);
    };

    return (
        <DialogWrapper
            open={UpdatePermissionsModalStore.isOpen}
            onClose={UpdatePermissionsModalStore.closeModal}
            title={conditional(
                displayName,
                `${t('updatePermissions')} for ${displayName}`,
                t('updatePermissions'),
            )}
            className={'UpdatePermissionsModal'}>
            {isAdminTppResellerFlow && <div className={'description'}>{t('description')}</div>}
            <div className={'formContainer'}>
                <form>
                    <Grid container spacing={4} item xs={12} className={'Container'}>
                        {permissionsList.map(
                            (permission) =>
                                (permission !== PERFORM_KYC || clientType === UNDER_RESELLER) && (
                                    //@ts-ignore
                                    <Grid
                                        item
                                        xs={6}
                                        md={6}
                                        className={'checkboxGroup permissions'}
                                        key={permission}
                                        value={permission}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={permission}
                                                    checked={permissions[permission]}
                                                    onClick={handleCheck}
                                                />
                                            }
                                            label={
                                                <Typography className={'label'}>
                                                    {t(permission)}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                ),
                        )}
                    </Grid>
                </form>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="center" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={
                                isAdminTppResellerFlow
                                    ? sendToAdminPage
                                    : UpdatePermissionsModalStore.closeModal
                            }>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!isFilled}
                            tooltipText={t('tooltipText')}
                            showTooltip
                            className={'saveButton'}>
                            {isAdminTppResellerFlow ? t('common:confirm') : t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore')(observer(UpdatePermissionsModal));
