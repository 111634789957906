import React, { ChangeEvent, ChangeEventHandler, useCallback } from 'react';
import { Grid, IconButton, InputAdornment, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import AppController from 'base/App.controller';
import bulletIcon from 'images/bullet-icon.svg';
import visibleOffTextIcon from 'images/icon-visible-off.svg';
import visibleTextIcon from 'images/icon-visible-on.svg';
import Icon from 'components/common/wrappers/IconWrapper';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { IPasswordFieldProps, PassFieldStoreValuesKeys } from '../types';
import { Itranslation } from 'types';

const styles: any = () => ({
    root: {},
    conditions: {
        padding: '0',
        marginBottom: '0',
        color: 'var(--milled-wine)',
        lineHeight: '28px',
        listStyle: 'none',
        fontWeight: 'var(--font-weight-regular)',
        fontSize: '0.875rem',
    },
    bullet: {
        fill: 'var(--spun-pearl)',
        marginRight: '7px',
    },
    condition: {},
    'condition-on': {},
    'condition-invalid': {},
    'bullet-on': {
        fill: 'var(--dark-pastel-green)',
    },
    'bullet-invalid': {
        fill: 'var(--bittersweet)',
    },
    icon: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

const PasswordField: React.FC<IPasswordFieldProps> = ({
    AppStore,
    PasswordFieldStore,
    className,
    classes,
    dontShowReq,
    label = '',
    name = 'password',
    onChange,
    readOnly = false,
    required,
    tooltipText,
    serverFieldError,
    value,
    autoFocus,
    showReEnterPasswordField = false,
    styles = { marginBottom: '50px' },
}) => {
    const {
        values,
        setValues,
        setIsLoading,
        toggleShowPassword,
        toggleReEnteredShowPassword,
        validationConditions,
        setPasswordNotInBreechedList,
        testForPasswordNotInBreechedList,
        handleChangePassword,
        handleChangeReEnteredPassword,
        checkIfPasswordsMatch,
        reEnteredPassword,
        calculateScore,
    } = PasswordFieldStore;
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'signup');
    const debounceFn = useCallback(_debounce(handleValidateBreechedPassword, 700), []);

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setValues(event.target.value);
        handleChangePassword(event.target.value);
        onChange(event);
        if (testForPasswordNotInBreechedList && event.target.value.length >= 8) {
            setIsLoading(true);
            debounceFn(event.target.value);
        } else {
            setPasswordNotInBreechedList(false);
        }
    };

    const handleReEnterPasswordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        handleChangeReEnteredPassword(event.target.value);
    };

    const _checkIfPasswordsMatch = () => {
        return checkIfPasswordsMatch(t);
    };

    function handleValidateBreechedPassword(passwordValue: string) {
        axios
            .post('/validate-breeched-passwords', {
                password: passwordValue,
            })
            .then((res) => {
                setPasswordNotInBreechedList(_get(res, 'data.passwordValid', false));
                setIsLoading(false);
            });
    }

    return (
        <div className={clsx(classes.root, className)} style={styles}>
            <TextFieldWrapper
                name={name}
                type={values.showPassword ? 'text' : 'password'}
                label={label}
                value={value}
                autoFocus={autoFocus}
                serverFieldError={serverFieldError}
                onChange={handleChange}
                required={required}
                tooltipText={tooltipText}
                autoComplete={'new-password'}
                InputProps={{
                    readOnly: readOnly,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                className={classes.icon}
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}>
                                {values.showPassword ? (
                                    <img src={visibleOffTextIcon} className="visible" />
                                ) : (
                                    <img src={visibleTextIcon} className="hidden" />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            {showReEnterPasswordField && (
                <div className={'PasswordField'}>
                    <TextFieldWrapper
                        className={'field MuiFormHelperText-root'}
                        name={'reEnterNewPassword'}
                        type={values.showReEnteredPassword ? 'text' : 'password'}
                        label={t('reEnterNewPassword')}
                        value={reEnteredPassword}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleReEnterPasswordChange(e)
                        }
                        required
                        customError={_checkIfPasswordsMatch}
                        autoComplete={'new-password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={classes.icon}
                                        aria-label="toggle password visibility"
                                        onClick={toggleReEnteredShowPassword}>
                                        {values.showReEnteredPassword ? (
                                            <img src={visibleOffTextIcon} className="visible" />
                                        ) : (
                                            <img src={visibleTextIcon} className="hidden" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            )}
            {!dontShowReq && (
                <>
                    <div className={'PasswordField'} style={{ marginTop: '10px' }}>
                        <PasswordStrengthMeter
                            password={value}
                            score={calculateScore(value)}
                            t={t}
                        />
                    </div>
                    <ul className={classes.conditions}>
                        <Grid container>
                            {validationConditions.map((key) => {
                                return (
                                    <Grid item xs={12} key={key}>
                                        <li
                                            className={clsx(
                                                classes.condition,
                                                classes[
                                                    `condition-${
                                                        values[key as PassFieldStoreValuesKeys]
                                                    }`
                                                ],
                                            )}>
                                            <Icon
                                                icon={bulletIcon}
                                                className={clsx(
                                                    classes.bullet,
                                                    classes[
                                                        `bullet-${
                                                            values[key as PassFieldStoreValuesKeys]
                                                        }`
                                                    ],
                                                )}
                                            />
                                            {t(key)}
                                        </li>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </ul>
                </>
            )}
        </div>
    );
};

export default withStyles(styles, { name: 'PasswordField' })(
    inject('AppStore')(observer(PasswordField)),
);
