import { withStyles, IconButton } from '@material-ui/core';

const StyledIcon = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
})(IconButton);

export default StyledIcon;
