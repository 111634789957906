import { Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../base/App.controller';
import successIcon from '../../../images/password-reset.svg';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import GenericSuccessModal from '../../common/modals/success/GenericSuccess';
import UpdatePermissionsModal from '../../common/modals/update-permissions/UpdatePermissionsModal';
import CustomThemeProvider from '../../common/theme/CustomThemeProvider';
import TextFieldWrapper from '../../common/wrappers/TextFieldWrapper';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import FormWrapper from '../../common/wrappers/FormWrapper';

function CreateTppStep2({ AppStore, TppManagerStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'create-tpp');
    const { sendToTppManager, CreateTppStore } = TppManagerStore;
    const {
        form,
        handleChange,
        handleSubmit,
        clearForm,
        isFormFilled,
        loading,
        isAdmin,
        sentToEditPermissionsModal,
    } = CreateTppStore;
    const ref = React.createRef();

    const _handleCancel = () => {
        sendToTppManager();
        return clearForm();
    };

    const _handleSubmit = async () => {
        await handleSubmit(AppStore);
    };

    const _renderForm = () => {
        return (
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="firstName"
                        type="text"
                        label={t('createAdmin.firstName')}
                        value={form.firstName}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="lastName"
                        type="text"
                        label={t('createAdmin.lastName')}
                        value={form.lastName}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="email"
                        type="email"
                        label={t('createAdmin.email')}
                        value={form.email}
                        onChange={handleChange}
                        required
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <div className={'createTppUser'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={_handleCancel}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Divider />
                <Grid container spacing={4}>
                    <Grid item xs={12} md={10} lg={8}>
                        <h2>{t('addTppUser')}</h2>
                        <p className={'instructions'}>{t('userInstructions')}</p>
                        <FormWrapper
                            className="form sectionParameters"
                            formRef={ref}
                            onSubmit={handleSubmit}
                            isValid={isFormFilled}>
                            {_renderForm()}
                        </FormWrapper>
                        <Grid className={'divider'} item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid className={'buttonContainer'} container spacing={4}>
                            <Grid item xs={3}>
                                <ButtonWrapper
                                    disabled={loading}
                                    className="cancelButton"
                                    color="secondary"
                                    onClick={_handleCancel}>
                                    {t('common:cancel')}
                                </ButtonWrapper>
                            </Grid>
                            <Grid item xs={3}>
                                <ButtonWrapper
                                    loading={loading}
                                    className="submitButton"
                                    onClick={_handleSubmit}
                                    disabled={!isFormFilled}>
                                    {isAdmin ? t('common:next') : t('common:complete')}
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
            <GenericSuccessModal
                Store={CreateTppStore.CompleteModalStore}
                onClick={isAdmin ? sentToEditPermissionsModal : sendToTppManager}
                title={t('completeModalTitle')}
                subtitle={isAdmin ? t('addConfigurations') : t('completeModalDescription')}
                buttonText={isAdmin ? t('common:next') : t('common:okay')}
                icon={successIcon}
            />
            {isAdmin && (
                <CustomThemeProvider alt>
                    <UpdatePermissionsModal
                        UpdatePermissionsModalStore={TppManagerStore.UpdatePermissionsModalStore}
                    />
                </CustomThemeProvider>
            )}
        </div>
    );
}

CreateTppStep2.propTypes = {
    AppStore: PropTypes.object.isRequired,
    TppManagerStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'TppManagerStore')(observer(CreateTppStep2));
