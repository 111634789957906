import React from 'react';
import { inject, observer } from 'mobx-react';
import { IPasswordStrengthMeterProps } from '../types';

const PasswordStrengthMeter: React.FC<IPasswordStrengthMeterProps> = ({ password, score, t }) => {
    const createPasswordLabel = (score: number | undefined) => {
        switch (score) {
            case 0:
                return 'Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    };
    return (
        <div className="password-strength-meter">
            <progress
                className={`password-strength-meter-progress strength-${createPasswordLabel(
                    score,
                )}`}
                value={score}
                max="4"
            />
            <br />
            <label className="password-strength-meter-label">
                {password && (
                    <>
                        <strong>{t('passwordStrength')}:</strong> {createPasswordLabel(score)}
                    </>
                )}
            </label>
        </div>
    );
};

export default inject('AppStore')(observer(PasswordStrengthMeter));
